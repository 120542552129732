////////////////////////////////
// HERO Portada
////////////////////////////////
.Hero {
    width: 100%; height: 0%; overflow: hidden; position: relative; padding-bottom: 45%;
    @include media-breakpoint-down(sm) {
        padding-bottom: 56%;
        width: calc(100% + .5rem); margin-left: -0.25rem;
    }
    @include media-breakpoint-down(md) {
        width: calc(100% + 2rem); margin-left: -1rem;
    }
    @include media-breakpoint-up(xl) {
        padding-bottom: 0; height: 480px;
    }
    .HeroBrand {
        position: absolute; left: 1.5rem; top:0; height:100%; width:140px; z-index: 2; display: block;
        .logo { width: 140px; height:200px; background: url(../img/logo7TV-2.svg) center center no-repeat; background-size: 200px; display: block; }
        .edicion { display: block; color: $gray-100; width: 140px; text-align: center; text-transform: uppercase; padding: .5rem 0 0; margin-top: .25rem; border-top: 2px solid $gray-300; white-space: nowrap; letter-spacing: 2px; font-size: 14px; }
        @include media-breakpoint-down(lg) {
            .logo { height: 160px; background-size: 160px;width: 110px;}
            .edicion { width: 110px;}
        }
    }
    .HeroTrigger {
        width: 100%; height: 100%; position: absolute; z-index: 2;
        a {
            * { transition: $transition-medium; }
            position: absolute; left: 50%; top:50%; transform: translate(-50%, -50%);
                &:before { content: ''; position: absolute; display: block; width: 140%; height: 140%; top:-20%; left: -20%; box-sizing: border-box; border-radius: 50%; background-color: white; animation: pulse-ring 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; z-index: -1; }
            @keyframes pulse-ring { 0% { transform: scale(.33); } 100% {  opacity: 0; } }
            @keyframes pulse-dot { 0% { transform: scale(.8); } 50% { transform: scale(1); } 100% { transform: scale(.8); } }
            i { font-size: 4.5rem; color: $gray-100; border: 3px solid $primary; background: rgba($gray-900 , 1); width: 7.125rem; height: 7.125rem; }
            span {position: absolute; left: 50%; top: calc(100% + .8rem); width: 100%; text-align: center; font-size: .8rem;text-transform: uppercase; color: white; font-weight: bold;line-height: 1em; min-width: 140px;transform: translateX(-50%);}
            &:hover { 
                cursor: pointer;
                i { color: $gray-100; background: $primary; cursor: pointer;}
            }
            @include media-breakpoint-down(md) {
                i { font-size: 3.5rem; width: 5.625rem; height: 5.625rem;}
            }
            @include media-breakpoint-down(sm) {
                i { font-size: 3rem; width: 4.875rem; height: 4.875rem;}
            }
        }
    }
    figure {
        width: 100%; height: 100%; position: absolute; padding: 0; margin:0; z-index: 1;
        &::before { content: ""; position: absolute; top:-1px; left: -1px; width: 101%; height: 25%; z-index: 1; @include grad-gray-900-vertical(); transform: rotate(180deg); opacity: .95;}
        &::after { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 25%; z-index: 1; @include grad-gray-900-vertical();}
        .img-wrap { width: 100%; height: 100%; position: relative;
            &::before { content: ""; position: absolute; top:-1px; left:-1px; width: 75%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(180deg);}
            &::after { content: ""; position: absolute; top:-1px; right:-1px; width: 25%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(0deg); }
            img { position: absolute; width: 100%; height: 100%;left: -1000%; right: -1000%; top: -1000%; bottom: -1000%; margin: auto; -o-object-fit: cover; object-fit: cover;}
        }
        @include media-breakpoint-down(md) {
            &::before { opacity: 0.75;}
            .img-wrap {
                &::before { width: 23%; opacity: 0.5; }
                &::after { width: 23%; opacity: 0.5; }
            }
        }
        @include media-breakpoint-down(sm) {
    
            .img-wrap {
                &::before { width: 13%; opacity: 0.5; }
                &::after { width: 13%; opacity: 0.5; }
            }
        }
    }
}

////////////////////////////////
// HERO Programa
////////////////////////////////
.HeroPrograma {
    width: 100%; height: 100%; overflow: hidden; position: relative;
    @include media-breakpoint-down(sm) {
        width: calc(100% + .5rem); margin-left: -0.25rem;
    }
    .wrapHeroPrograma {
        @include media-breakpoint-down(md) { position: relative!important; }
        .HeroDetail {
            // border:1px solid green;
            position: absolute; left: 0.75rem; top:0; height:100%; z-index: 2; display: block; width:44%; text-shadow: 1px 1px 2px rgb(0 0 0 / 50%);
            .btn { text-shadow: none!important;}
            @include media-breakpoint-down(xl) { width:55%; }
            @include media-breakpoint-down(lg) { width:66%; }
            @include media-breakpoint-down(md) { width:calc(100% - 3rem);left: 0rem; padding-top: 45%; height: auto; position: relative;}
            h1 { color: $gray-100;}
            h6 { /*font-family: $font-family-secondary;*/}
        }
        .HeroTrigger {
            height: 100%; position: absolute; z-index: 2; right: 0.75rem; width: 54%;
            // border:1px solid red;
            @include media-breakpoint-down(xl) { width:43%; }
            @include media-breakpoint-down(lg) { width:30%; }
            @include media-breakpoint-down(md) { width: 100%; left:0; height: 0; padding-bottom: 45%; top: 0;}
            a {
                * { transition: $transition-medium; }
                position: absolute; left: 50%; top:50%; transform: translate(-50%, -50%);
                &:before { content: ''; position: absolute; display: block; width: 140%; height: 140%; top:-20%; left: -20%; box-sizing: border-box; border-radius: 50%; background-color: white; animation: pulse-ring 1.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; z-index: -1; }
                @keyframes pulse-ring { 0% { transform: scale(.33); } 100% {  opacity: 0; } }
                @keyframes pulse-dot { 0% { transform: scale(.8); } 50% { transform: scale(1); } 100% { transform: scale(.8); } }
                i { font-size: 4.5rem; color: $gray-100; border: 3px solid $primary; background: rgba($gray-900 , 1); width: 7.125rem; height: 7.125rem; }
                span {position: absolute; left: 50%; top: calc(100% + .8rem); width: 100%; text-align: center; font-size: .8rem;text-transform: uppercase; color: white; font-weight: bold;line-height: 1em; min-width: 120px;transform: translateX(-50%);}
                &:hover {
                    cursor: pointer;
                    i { color: $gray-100; background: $primary; cursor: pointer;}
                }
                @include media-breakpoint-down(xl) {
                    i { font-size: 4rem; width: 6.375rem; height: 6.375rem;}
                }
                @include media-breakpoint-down(lg) {
                    i { font-size: 3.5rem; width: 5.625rem; height: 5.625rem;}
                }
                @include media-breakpoint-down(md) {
                    i { font-size: 3rem; width: 4.875rem; height: 4.875rem;}
                }
            }
        }
    }
    figure {
        width: 100%; height: 0%; position: relative; padding: 0; margin:0; z-index: 1; padding-bottom: $aspect-ratio-16-9;
        &::before { content: ""; position: absolute; top:-1px; left: -1px; width: 101%; height: 25vh; z-index: 1; @include grad-gray-900-vertical(); transform: rotate(180deg); opacity: .95;}
        &::after { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 25vh; z-index: 1; @include grad-gray-900-vertical();}
        .img-wrap { width: 100%; height: 100%; position: absolute;
            &::before { content: ""; position: absolute; top:-1px; left:-1px; width: 100%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(180deg);}
            &::after { content: ""; position: absolute; top:-1px; right:-1px; width: 20%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(0deg); }
            img { position: absolute; width: 100%; height: 100%;left: -1000%; right: -1000%; top: -1000%; bottom: -1000%; margin: auto; -o-object-fit: cover; object-fit: cover;}
        }
        @include media-breakpoint-up(md) { 
            min-height: 480px;
        }
        @include media-breakpoint-up(xl) { 
            padding-bottom: 0; height: 728px;
        }
        @include media-breakpoint-down(lg) {
            &::after { height: 75%; }
        }
        @include media-breakpoint-down(md) {
            height: auto; top:0; position: absolute;
            &::before { opacity: 0.5; }
            .img-wrap {
                &::before { width: 23%; opacity: 0.5; }
                &::after { width: 23%; }
            }
        }
    }
}