
// TRANSITIONS
$transition-short: all .2s ease-in-out;
$transition-medium: all .5s ease-in-out;
$transition-long: all .8s ease-in-out;
$transition-fade:  opacity .15s linear;

$enable-negative-margins: true;

// ASPECT RATIO
// 1:1 aspect ratio = 1 / 1 = 1 = padding-top: 100%
// 4:3 aspect ratio = 3 / 4 = 0.75 = padding-top: 75%
// 3:2 aspect ratio = 2 / 3 = 0.66666 = padding-top: 66.67%
// 16:9 aspect ratio = 9 / 16 = 0.5625 = padding-top: 56.25%
$aspect-ratio-4-3: 75%;
$aspect-ratio-3-2: 66.67%;
$aspect-ratio-16-9: 56.25%;
$aspect-ratio-21-9: 42.85%;
$aspect-ratio-programa: 140%;

// SPACINGS FOR NAV + SIDEBAR
:root {
    --nav-space-mob:60px;
    --nav-space-tablet:72px;
    --nav-space-pc:80px;
}

// PROGRESS BARS
$progress-height: 3px;
$progress-bg: transparent;
$progress-border-radius: 0;
$progress-bar-bg: $primary-shade;

// BUTTONS
$btn-border-width: 2px;
// $btn-font-family: 'Poppins', sans-serif;

// MODALS
$modal-backdrop-opacity: .85;
$modal-transition: transform .75s ease-in-out;
.offcanvas-backdrop { transition: opacity .75s ease-in-out;}
.offcanvas-backdrop::before { transition: opacity .75s ease-in-out;}

// BORDER RADIUS
$border-radius: .35rem;
$border-radius-sm: .2rem;
$border-radius-lg: .75rem;


// UTILITIES
$utilities: (
    // MAX WIDTH
    "max-width": (
        property: max-width,
        class: mw,
        values: (
            100: 100%,
            90: 90%,
            75: 75%,
            50: 50%,
            25: 25%,
            0: 0%,
        )
    ),
    // VIEWPORT HEIGHT
    "viewport-height": (
        property: height,
        class: vh,
        values: (
            100: 100vh,
            75: 75vh,
            50: 50vh,
            25: 25vh,
            20: 20vh,
            15: 15vh,
            10: 10vh,
        )
    ),
);

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px
);
// CONTAINERS
$container-max-widths: (
  xxl: 1420px
);

// SWIPER
:root {
    --swiper-navigation-size: 32px!important;
    --swiper-navigation-color:white!important;
    --swiper-navigation-color:white!important;
    --swiper-theme-color:#BC1622!important;
}

// TOOLTIPS
$tooltip-font-size: 12px;
$tooltip-bg: $primary-shade;

// BUTTONS + FORMS
// $font-size-root: null;
// $font-size-base: 1rem; // Assumes the browser default, typically `16px`
// $input-btn-font-size: $font-size-base * .875;
// $font-size-sm: $font-size-base * .75;
// $font-size-lg: $font-size-base * .95;
// $input-btn-padding-y-lg:      .75rem;
// $input-btn-padding-x-lg:      1.25rem;



@keyframes gradient {
	0% { background-position: 0% 50%;}
	50% { background-position: 0% 100%;	}
	100% { background-position: 0% 50%;	}
}


// SHADOWS
:root {
    --shadow-m:
    0px 1.3px 2.2px rgba(0, 0, 0, 0.141),
    0px 3.2px 5.3px rgba(0, 0, 0, 0.202),
    0px 6px 10px rgba(0, 0, 0, 0.25),
    0px 10.7px 17.9px rgba(0, 0, 0, 0.298),
    0px 20.1px 33.4px rgba(0, 0, 0, 0.359),
    0px 48px 80px rgba(0, 0, 0, 0.95)
    ;
}
.shadow-m { box-shadow: var(--shadow-m);}