////////////////////////////////
// SWIPER
////////////////////////////////
.swiper {
    width: 100%; height: 100%;
    .swiper-wrapper {
        h4 { line-height: 1;}
        .swiper-slide { 
            background: linear-gradient(180deg, $gray-700 , $gray-900); background-size: 400% 400%; animation: gradient 2s ease infinite;
        }
        .swiper-slide .slide-wrap figure .img-wrap img { 
            position: relative; 
            // &::after {content:"";position: absolute; left: 0; top:0; width: 100%; height: 100%; background: red;}
        }
    }
    .swiper-button-next, .swiper-button-prev {
        background: rgba(255,255,255,0.25); padding:2rem 1.5rem;transition: $transition-short;
        top:5%; height: 90%; margin: 0;
        &::after { color: $gray-500; font-size: 28px; position: absolute; right: 0; transition: $transition-medium; }
        &::before { color: $gray-100; font-size: 28px; position: absolute; left: 0;}
        &.swiper-button-disabled { opacity: 0;}
        &:hover { 
            // background: $primary-shade;
            &::after , &::before { color: $gray-100;}
        }
    }
    .swiper-button-next { 
        right:0; @include grad-gray-900-horizontal(); opacity: .75;
        @include media-breakpoint-down(sm) {
            background: transparent!important; right: 10px;
        }
    }
    .swiper-button-prev { 
        left: 0;@include grad-gray-900-horizontal-inv(); opacity: .75;
        @include media-breakpoint-down(sm) {
            background: transparent!important; left: 10px
        }
        &::after { color: $gray-100; font-size: 28px; position: absolute; left: 0; }
    }
}


////////////////////////////////
// SliderEpisodios
////////////////////////////////
.SliderEpisodios {
    padding-top: 2rem!important; margin-top: -2rem;
    .swiper-wrapper {
        .swiper-slide{
            position: relative; transition: $transition-short;
            .slide-top {
                position: absolute; top:0; right: 0; width: 100%; max-height: 2rem; z-index: 3; display:flex; justify-content: flex-end; padding-right: 0.5rem;
                button {
                    color: $gray-100; padding:.5rem 0.25rem; text-decoration: none;
                    i { font-size: 1.25rem;}
                    &.active , &:hover { color:$primary; cursor: pointer;}
                }
            }
            .slide-wrap { position: relative; display: block;
                .slide-content {
                    position: absolute; bottom:0; right: 0; left:0; width: 100%; /*height:calc(100% - 2rem);*/ padding:0 1rem 1rem 1rem; z-index: 3; 
                    display: flex; flex-direction: column; justify-content: flex-end; text-shadow: 0px 1px 7px rgb(0 0 0 / 50%);transition: $transition-short;
                    h4 { color: $gray-100;}
                    h5 { color: $primary; /*font-family: 'Poppins', sans-serif;*/ font-size: 14px;margin-bottom: 0.25rem;}
                    h6 { color: $gray-500; font-size: 12px; text-transform: uppercase; font-weight: 400; margin:0;}
                    &:hover {cursor: pointer; }
                }
                i { 
                    position: absolute; z-index: 4; opacity: 0; transition: $transition-medium; left: 50%; top:50%; transform: translate(-50%, -50%);font-size: 2rem; color: $gray-100; border: 2px solid $primary; background:$primary; 
                    &:hover { background: $primary-shade;border: 2px solid $primary-shade;}
                }
                figure {
                    width: 100%; height: 100%; position: relative; padding: 0; margin:0;
                    &::before { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 100%; z-index: 1; @include grad-gray-900-vertical(); opacity: .95;}
                    .img-wrap { width: 100%; height: 0%; position: relative; padding-bottom: $aspect-ratio-16-9;
                        img { position: absolute; width: 100%; height: 100%;left: -1000%; right: -1000%; top: -1000%; bottom: -1000%; margin: auto; -o-object-fit: cover; object-fit: cover; opacity: .7; transition: $transition-medium;
                            outline: 1px solid $gray-900; outline-offset: -1px;
                        }
                    }
                }
            }
            .progress { position:absolute; bottom: 0; left: 0; z-index: 2; width: 100%;}
            &::after { content: ""; position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 2; outline: 3px solid transparent; outline-offset: -3px; transition: $transition-short;}
            &:hover { cursor: pointer;
                // transform: scale(1.1); z-index: 5;
                &::after { outline: 4px solid $primary-shade;}
                .slide-wrap {
                    .slide-content {
                        bottom:-25%; bottom:-1.5rem;
                        h5, h6 {opacity: 0; }
                    }
                    i { opacity: 1;}
                    figure { 
                        img { opacity: 1;}
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        padding-top: 1rem!important; margin-top: -2rem; 
        // padding-right: 20px!important; padding-left: 20px!important;margin-left: -20px!important;
        .swiper-wrapper {
            padding: 20px 0;
            .swiper-slide{ 
                &:hover { 
                    transform: scale(1.1); z-index: 5;  box-shadow: var(--shadow-m);
                }
                &:first-child:hover{
                    margin:0 0 0 20px;
                }
            }
        }
    }
}
.swiper-pagination.PaginationEpisodios {
    bottom: auto!important; left: auto!important; right: 0; text-align: right; top: 0rem; transform: translate(0)!important;
    .swiper-pagination-bullet{ background: $gray-700; opacity: 1; width: 20px; height: 4px; border-radius: 0; transform: scale(1)!important; 
        left: 0!important;;
        &.swiper-pagination-bullet-active { background: $primary;}
    }
}

////////////////////////////////
// SliderProgramas
////////////////////////////////
.SliderProgramas { 
    padding-top: 2rem!important; margin-top: -2rem;
    .swiper-wrapper {
        .swiper-slide {
            position: relative; overflow: hidden; transition: $transition-short;
            .slide-top {
                position: absolute; top:0; right: 0; width: 100%; max-height: 2rem; z-index: 3; display:flex; justify-content: flex-end; padding-right: 0.5rem;
                .temps { color: $gray-200; flex-grow: 1; font-size: 11px; font-weight: 700; text-transform: uppercase; padding: 0.75rem 0.75rem; position: relative;
                    span { position: relative; z-index: 2; opacity: .75;}
                    &::after {
                        content: ""; z-index: 1; position: absolute; top:1px; left: 1px; display: inline-block; width: 0; height: 0; border-style: solid; border-width: 40px 40px 0 0; border-color: $primary-shade transparent transparent transparent;
                    }
                }
                button {
                    color: $gray-100; padding:.5rem 0.25rem; text-decoration: none;
                    i { font-size: 1.25rem;}
                    &.active , &:hover { color:$primary; cursor: pointer;}
                }
            }
            .slide-wrap { position: relative; display: block;
                .slide-content {
                    position: absolute; bottom:0; right: 0; left:0; width: 100%; /*height:calc(100% - 2rem);*/ padding:0 1.5rem 2rem 1.5rem; z-index: 3; text-align: center;
                    display: flex; flex-direction: column; justify-content: flex-end; text-shadow: 0px 1px 7px rgb(0 0 0 / 50%);
                    * { transition: $transition-medium;}
                    h4 { color: $gray-100; position: relative; display: flex;
                        span { 
                            font-size: 1.15em; padding:0 10px;
                            @include media-breakpoint-down(sm) {
                                font-size: 1.5em;
                            }
                        }
                        &::after , &::before { 
                            content: ""; margin-top: 10px; height: 1px; background: rgba(255,255,255,0.25); width: auto; flex-grow: 1; min-width: 10px;
                        }
                        &::before { margin-left:-10px; }
                        &::after { margin-right:-10px; }
                    }
                    p.resPrograma { 
                        font-size: .8rem; line-height: 1.2; color: $gray-400; margin: 0 0 .75rem 0; max-height: 0; overflow: hidden;display:-webkit-box;  -webkit-line-clamp: 6; -webkit-box-orient: vertical;transition: $transition-long;
                        @include media-breakpoint-down(sm) {
                            font-size: .9rem;
                        }
                    }
                    h6 { color: $gray-500; font-size: 12px; text-transform: uppercase; font-weight: 400; margin:0 0 0 0;  transition: $transition-fade;}
                    &:hover {cursor: pointer; }
                    &::after { content:""; position: absolute; top:10px; left:calc(.75rem + 1px); width: calc(100% - 1.5rem - 2px); height: calc(100% - 1rem - 10px); border:1px solid rgba(255,255,255,0.25); border-top:none; }
                }
                i { 
                    position: absolute; z-index: 4; opacity: 0; transition: $transition-medium; left: 50%; top:50%; transform: translate(-50%, -50%);
                    font-size: 1.75rem; color: $gray-100; border: 2px solid $primary; background:$primary; padding: 0.75rem; border-radius: 50%;
                    &:hover { background: $primary-shade;border: 2px solid $primary-shade;}
                }
                figure {
                    width: 100%; height: 100%; position: relative; padding: 0; margin:0;
                    &::before { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 100%; z-index: 1; @include grad-gray-900-vertical(); opacity: .95;}
                    .img-wrap { width: 100%; height: 0%; position: relative; padding-bottom: $aspect-ratio-programa;
                        img { position: absolute; width: 100%; height: 100%;left: -1000%; right: -1000%; top: -1000%; bottom: -1000%; margin: auto; -o-object-fit: cover; object-fit: cover; opacity:.7; transition: $transition-medium;
                            outline: 1px solid $gray-900; outline-offset: -1px;
                        }
                    }
                }
            }
            .progress { position:absolute; bottom: 0; left: 0; z-index: 2; width: 100%;}
            &::after { content: ""; position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 2; outline: 3px solid transparent; outline-offset: -3px; transition: $transition-short;}
            &:hover {
                cursor: pointer;
                &::after { outline: 4px solid $primary-shade;}
                .slide-wrap {
                    .slide-content {
                        bottom:0;
                        h4 { /*margin-bottom: -15px;*/
                            &::after , &::before { 
                                background: rgba(255,255,255,0.5);
                            }
                        }
                        p.resPrograma { max-height:300px;}
                        h5, h6 {/*opacity: 0; /*display: none;*/ }
                        &::after { border:1px solid rgba(255,255,255,0.5); border-top: none!Important; }
                    }
                    i { opacity: 1;}
                    figure { 
                        img { opacity: 1;}
                    }
                }
                @include media-breakpoint-up(xl) {
                    transform: scale(1.1); z-index: 5; box-shadow: var(--shadow-m);
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {
        padding-top: 1rem!important; margin-top: -2rem; 
        // padding-right: 20px!important; padding-left: 20px!important;margin-left: -20px!important;
        .swiper-wrapper {
            padding: 20px 0;
            .swiper-slide{ 
                &:hover { 
                    transform: scale(1.1); z-index: 5;  box-shadow: var(--shadow-m);
                }
                &:first-child:hover{
                    margin:0 0 0 20px;
                }
            }
        }
    }
}
.swiper-pagination.PaginationProgramas {
    bottom: auto!important; left: auto!important; right: 0; text-align: right; top: 0rem; transform: translate(0)!important;
    .swiper-pagination-bullet{ background: $gray-700; opacity: 1; width: 20px; height: 4px; border-radius: 0; transform: scale(1)!important; 
        left: 0!important;;
        &.swiper-pagination-bullet-active { background: $primary;}
    }
}


////////////////////////////////
// ListadoProgramas
////////////////////////////////
.ListadoProgramas {
    display: flex; flex-direction: row; flex-wrap: wrap; gap: 6px;
    .ficha-programa {
        width: 100%; margin-bottom: 1rem;
        @include media-breakpoint-up(sm) { width: calc(50% - 3px); }
        @include media-breakpoint-up(md) { width: calc(33% - 2px); }
        @include media-breakpoint-up(xl) { width: calc(25% - 5px); }
        @include media-breakpoint-up(xxl) { width: calc(20% - 5px); }
    }
}
.ficha-programa {
    position: relative; overflow: hidden; transition: $transition-short;
    .ficha-top {
        position: absolute; top:0; right: 0; width: 100%; max-height: 2rem; z-index: 3; display:flex; justify-content: flex-end; padding-right: 0.5rem;
        .temps { color: $gray-200; flex-grow: 1; font-size: 11px; font-weight: 700; text-transform: uppercase; padding: 0.75rem 0.75rem; position: relative;
            span { position: relative; z-index: 2; opacity: .75;}
            &::after {
                content: ""; z-index: 1; position: absolute; top:1px; left: 1px; display: inline-block; width: 0; height: 0; border-style: solid; border-width: 40px 40px 0 0; border-color: $primary-shade transparent transparent transparent;
            }
        }
        button {
            color: $gray-100; padding:.5rem 0.25rem; text-decoration: none;
            i { font-size: 1.25rem;}
            &.active , &:hover { color:$primary; cursor: pointer;}
        }
    }
    .ficha-wrap { position: relative; display: block;
        .ficha-content {
            position: absolute; bottom:0; right: 0; left:0; width: 100%; /*height:calc(100% - 2rem);*/ padding:0 1.5rem 2rem 1.5rem; z-index: 3; text-align: center;
            display: flex; flex-direction: column; justify-content: flex-end; text-shadow: 0px 1px 7px rgb(0 0 0 / 50%);
            * { transition: $transition-medium;}
            h4 { color: $gray-100; position: relative; display: flex;
                span { 
                    font-size: 1.15em; padding:0 10px;
                    @include media-breakpoint-down(sm) {
                        font-size: 1.5em;
                    }
                }
                &::after , &::before { 
                    content: ""; margin-top: 10px; height: 1px; background: rgba(255,255,255,0.25); width: auto; flex-grow: 1; min-width: 10px;
                }
                &::before { margin-left:-10px; }
                &::after { margin-right:-10px; }
            }
            p.resPrograma { 
                display: -webkit-box; font-size: .8rem; line-height: 1.2; color: $gray-400; margin: 0 0 .75rem 0; max-height: 0; overflow: hidden;  -webkit-line-clamp: 6; -webkit-box-orient: vertical;transition: $transition-long;
                @include media-breakpoint-down(sm) {
                    font-size: .9rem;
                }
            }
            h6 { color: $gray-500; font-size: 12px; text-transform: uppercase; font-weight: 400; margin:0 0 0 0;  transition: $transition-fade;}
            &:hover {cursor: pointer; }
            &::after { content:""; position: absolute; top:10px; left:calc(.75rem + 1px); width: calc(100% - 1.5rem - 2px); height: calc(100% - 1rem - 10px); border:1px solid rgba(255,255,255,0.25); border-top:none; }
        }
        i { 
            position: absolute; z-index: 4; opacity: 0; transition: $transition-medium; left: 50%; top:50%; transform: translate(-50%, -50%);
            font-size: 1.75rem; color: $gray-100; border: 2px solid $primary; background:$primary; padding: 0.75rem; border-radius: 50%;
            &:hover { background: $primary-shade;border: 2px solid $primary-shade;}
        }
        figure {
            width: 100%; height: 100%; position: relative; padding: 0; margin:0;
            &::before { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 100%; z-index: 1; @include grad-gray-900-vertical(); opacity: .95;}
            .img-wrap { width: 100%; height: 0%; position: relative; padding-bottom: $aspect-ratio-programa;
                img { position: absolute; width: 100%; height: 100%;left: -1000%; right: -1000%; top: -1000%; bottom: -1000%; margin: auto; -o-object-fit: cover; object-fit: cover; opacity:.7; transition: $transition-medium;
                    outline: 1px solid $gray-900; outline-offset: -1px;
                }
            }
        }
    }
    .progress { position:absolute; bottom: 0; left: 0; z-index: 2; width: 100%;}
    &::after { content: ""; position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 2; outline: 3px solid transparent; outline-offset: -3px; transition: $transition-short;}
    &:hover {
        cursor: pointer;
        &::after { outline: 4px solid $primary-shade;}
        .ficha-wrap {
            .ficha-content {
                bottom:0;
                h4 { /*margin-bottom: -15px;*/
                    &::after , &::before { 
                        background: rgba(255,255,255,0.5);
                    }
                }
                p.resPrograma { max-height:300px;}
                h5, h6 {/*opacity: 0; /*display: none;*/ }
                &::after { border:1px solid rgba(255,255,255,0.5); border-top: none!Important; }
            }
            i { opacity: 1;}
            figure { 
                img { opacity: 1;}
            }
        }
        @include media-breakpoint-up(xl) {
            transform: scale(1.1); z-index: 5; box-shadow: var(--shadow-m);
        }
    }
}


////////////////////////////////
// ListadoEpisodios
////////////////////////////////
.ListadoEpisodios {
    display: flex; flex-direction: row; flex-wrap: wrap; gap: 6px;
    .ficha-episodio { 
        width: 100%; margin-bottom: 1rem;
        @include media-breakpoint-up(sm) { width: calc(50% - 3px); }
        @include media-breakpoint-up(lg) { width: calc(33% - 1px); }
        @include media-breakpoint-up(xxl) { width: calc(25% - 5px); }
        // @include media-breakpoint-up(xxl) { width: calc(20% - 20px); }
    }
}
.ficha-episodio {
    position: relative; transition: $transition-short;
    .ficha-top {
        position: absolute; top:0; right: 0; width: 100%; max-height: 2rem; z-index: 3; display:flex; justify-content: flex-end; padding-right: 0.5rem;
        button {
            color: $gray-100; padding:.5rem 0.25rem; text-decoration: none;
            i { font-size: 1.25rem;}
            &.active , &:hover { color:$primary; cursor: pointer;}
        }
    }
    .ficha-wrap { position: relative; display: block;
        .ficha-content {
            position: absolute; bottom:0; right: 0; left:0; width: 100%; height:calc(100% - 2rem); padding:0 1rem 1rem 1rem; z-index: 3; 
            display: flex; flex-direction: column; justify-content: flex-end; text-shadow: 0px 1px 7px rgb(0 0 0 / 50%);transition: $transition-short;
            h4 { color: $gray-100;}
            h5 { color: $primary; /*font-family: 'Poppins', sans-serif;*/ font-size: 14px;margin-bottom: 0.25rem;}
            h6 { color: $gray-500; font-size: 12px; text-transform: uppercase; font-weight: 400; margin:0;}
            &:hover {cursor: pointer; }
        }
        i { 
            position: absolute; z-index: 4; opacity: 0; transition: $transition-medium; left: 50%; top:50%; transform: translate(-50%, -50%);font-size: 2rem; color: $gray-100; border: 2px solid $primary; background:$primary; 
            &:hover { background: $primary-shade;border: 2px solid $primary-shade;}
        }
        figure {
            width: 100%; height: 100%; position: relative; padding: 0; margin:0;
            &::before { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 100%; z-index: 1; @include grad-gray-900-vertical(); opacity: .95;}
            .img-wrap { width: 100%; height: 0%; position: relative; padding-bottom: $aspect-ratio-16-9;
                img { position: absolute; width: 100%; height: 100%;left: -1000%; right: -1000%; top: -1000%; bottom: -1000%; margin: auto; -o-object-fit: cover; object-fit: cover; opacity: .7; transition: $transition-medium;
                    outline: 1px solid $gray-900; outline-offset: -1px;
                }
            }
        }
    }
    .progress { position:absolute; bottom: 0; left: 0; z-index: 2; width: 100%;}
    &::after { content: ""; position: absolute; top:0; left: 0; width: 100%; height: 100%; z-index: 2; outline: 3px solid transparent; outline-offset: -3px; transition: $transition-short;}
    &:hover { cursor: pointer;
        &::after { outline: 4px solid $primary-shade;}
        .ficha-wrap {
            .ficha-content {
                bottom:-25%; bottom:-1.5rem;
                h5, h6 {opacity: 0; }
            }
            i { opacity: 1;}
            figure { 
                img { opacity: 1;}
            }
        }
        @include media-breakpoint-up(xl) {
            transform: scale(1.1); z-index: 5; box-shadow: var(--shadow-m);
        }
    }
}


////////////////////////////////
// SliderCategorias
////////////////////////////////
.SliderCategorias {
    position: relative;
    .pn-ProductNav_Wrapper {
        position: relative; padding: 0; box-sizing: border-box;
    }
    .pn-ProductNav {
        overflow-x: auto; overflow-y: hidden; -webkit-overflow-scrolling: touch; white-space: nowrap; position: relative; font-size: 0;
    }
    .pn-ProductNav {
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .pn-ProductNav::-webkit-scrollbar {
        display: none;
    }
    .pn-ProductNav_Contents {
        float: left;  -webkit-transition: -webkit-transform .2s ease-in-out; transition: -webkit-transform .2s ease-in-out; transition: transform .2s ease-in-out; transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out; position: relative;
    }
    .pn-ProductNav_Contents-no-transition {
        -webkit-transition: none; transition: none;
    }
    .pn-ProductNav_Link {
        text-decoration: none; color: $gray-500; font-size: 1rem; font-weight: 700; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center;
        min-height: 48px; border: 1px solid transparent; padding: 0 1.25rem;margin: 0 1rem 0 0; background-color: $gray-800;
        &:hover { color: $gray-100!important; border-color: $gray-700!important; background-color: $gray-700;}
    }
    .pn-ProductNav_Link + .pn-ProductNav_Link {
        // border-left-color: #eee;
    }
    .pn-ProductNav_Link[aria-selected="true"] {
        color: $gray-900!important; border-color: $gray-200!important; background-color: $gray-200;
    }
    .pn-Advancer {
        -webkit-appearance: none; -moz-appearance: none; appearance: none; background: transparent; padding: 0; border: 0;
        position: absolute; top: 0; bottom: 0;
        opacity: 0; -webkit-transition: opacity .3s; transition: opacity .3s;
    }
    .pn-Advancer:focus {
        outline: 0;
    }
    .pn-Advancer:hover {
        cursor: pointer;
    }
    .pn-Advancer_Left {
        left: 0;
    }
    [data-overflowing="both"] ~ .pn-Advancer_Left, [data-overflowing="left"] ~ .pn-Advancer_Left {
        opacity: 1; padding: 0 2rem 0 0; /*box-shadow: var(--shadow-m);background: rgba(0,0,0,0.5);*/ @include grad-gray-900-horizontal-inv(); opacity: .75;
    }
    .pn-Advancer_Right {
        right: 0;
    }
    [data-overflowing="both"] ~ .pn-Advancer_Right, [data-overflowing="right"] ~ .pn-Advancer_Right {
        opacity: 1;  padding: 0 0 0 2rem; /*box-shadow: var(--shadow-m);background: rgba(0,0,0,0.5);*/ @include grad-gray-900-horizontal(); opacity: .75;
    }
    .pn-Advancer_Icon {
        width: 20px; height: 44px; fill: $gray-600; transition: $transition-short;
        &:hover { fill:$primary;}
    }
    .pn-ProductNav_Indicator { display: none!important;
        position: absolute; bottom: 0; left: 0; height: 4px; width: 100px; background-color: transparent; -webkit-transform-origin: 0 0; transform-origin: 0 0; 
        -webkit-transition: background-color .2s ease-in-out, -webkit-transform .2s ease-in-out;
        transition: background-color .2s ease-in-out, -webkit-transform .2s ease-in-out; transition: transform .2s ease-in-out, background-color .2s ease-in-out;
        transition: transform .2s ease-in-out, background-color .2s ease-in-out, -webkit-transform .2s ease-in-out;
    }
    .pn-Advancer {
        &::after { content: ""; font-family: 'swiper-icons'; color: $gray-500; font-size: 28px;  transition: $transition-medium; }
        &:hover {
            &::after { color: $gray-100;}
        }
    }
    #pnAdvancerLeft::after  { content: "prev"; }
    #pnAdvancerRight::after  { content: "next"; }
}

////////////////////////////////
// TIPO_bloqueado : Bloqueos de contenido
////////////////////////////////
.TIPO_bloqueado { position: relative; transform: scale(1)!important;
    &::before { 
        content: "\ee0c";font-family: boxicons!important; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(10,10,10,.35); z-index: 1; display: flex; justify-content: center; align-items: center;
        font-size: 3rem;  text-shadow: var(--shadow-m);
        @include media-breakpoint-down(sm) {
            font-size: 4rem;
        }
    }
    .slide-wrap, .ficha-wrap {
        figure {
            .img-wrap {
                background-color: $primary!important;
                img {mix-blend-mode: multiply;}
            }
        }
        .resPrograma ,
        .bx-play { display: none!important;}
    }
    &.Episodio {
        &::before { 
            font-size: 2.5rem; padding-bottom: 3.5rem;
        }
        .slide-content , .ficha-content {
            bottom: 0 !important;
            h5, h6 { opacity: 1!important;}
        }
    }
}
////////////////////////////////
// Filtros
////////////////////////////////
.Filtros{
    button.btn-gray-800 {
        &:hover , &.show { background-color: $gray-700;
            span { color: $gray-100!important;}
        }
    }
    .dropdown-menu-dark {
        background-color: $gray-700; left: auto!important; right: 0!important;top: 8px!important;transform: translate(0px, 52px)!important;
        &::after { content: ""; position:absolute; bottom: 100%; right: 1.4rem; display: inline-block; width: 0; height: 0; border-style: solid; border-width: 0 6px 6px 6px; border-color: transparent transparent $gray-700 transparent;}
    }
}