// CUSTOM COLORS
$primary : #BC1622;
$primary-shade : #981A20;
$primary-tint : #DC0B21;
$dark : #000000;
$gray-900 : #0A0A0A;
$gray-850 : #141414;
$gray-800 : #1F1E1F;
$gray-750 : #262526;
$gray-700 : #363436;
$gray-600 : #5B5D5C;
$gray-500 : #8E908F;
$gray-400 : #C1C2C2;
$gray-300 : #D6D7D7;
$gray-200 : #EAEBEB;
$gray-100 : #F5F5F5;
$white : #fff;
$success : #30A461;

$theme-colors: (
    "primary": $primary,
    "primary-shade": $primary-shade,
    "primary-tint": $primary-tint,
    "dark": $dark,
    "gray-900": $gray-900,
    "gray-850": $gray-850,
    "gray-800": $gray-800,
    "gray-700": $gray-700,
    "gray-600": $gray-600,
    "gray-500": $gray-500,
    "gray-400": $gray-400,
    "gray-300": $gray-300,
    "gray-200": $gray-200,
    "gray-100": $gray-100,
    "white": $white,
    "success": $success,
);
// BLUR
@mixin Blur(){
    -webkit-backdrop-filter: saturate(180%) blur(5px); backdrop-filter: saturate(180%) blur(5px);
}
.CajaBlur { background:rgba(0, 0, 0, 0.7)!important; @include Blur();}
.CajaBlurLogin { background:rgba(0, 0, 0, 0.8)!important; @include Blur();}

// GRADIENTS

@mixin grad-gray-900-vert(){
    background: -moz-linear-gradient(top,  $gray-900 0%, rgba($gray-900, 0) 100%);
    background: -webkit-linear-gradient(top,  $gray-900 0%, rgba($gray-900, 0) 100%);
    background: linear-gradient(to bottom,  $gray-900 0%, rgba($gray-900, 0) 100%);
}
@mixin grad-gray-900-vert-inv(){
    background: -moz-linear-gradient(bottom,  $gray-900 0%, rgba($gray-900, 0) 100%);
    background: -webkit-linear-gradient(bottom,  $gray-900 0%, rgba($gray-900, 0) 100%);
    background: linear-gradient(to top,  $gray-900 0%, rgba($gray-900, 0) 100%);
}
@mixin grad-gray-900-hor(){
    background: -moz-linear-gradient(left,  $gray-900 0%, rgba($gray-900, 0) 100%);
    background: -webkit-linear-gradient(left,  $gray-900 0%, rgba($gray-900, 0) 100%);
    background: linear-gradient(to left,  $gray-900 0%, rgba($gray-900, 0) 100%);
}
@mixin grad-gray-1000-vertical(){
background:
    linear-gradient(
        to top,
        hsl(0, 0%, 0) 0%,
        hsla(0, 0%, 0, 0.987) 8.1%,
        hsla(0, 0%, 0, 0.951) 15.5%,
        hsla(0, 0%, 0, 0.896) 22.5%,
        hsla(0, 0%, 0, 0.825) 29%,
        hsla(0, 0%, 0, 0.741) 35.3%,
        hsla(0, 0%, 0, 0.648) 41.2%,
        hsla(0, 0%, 0, 0.55) 47.1%,
        hsla(0, 0%, 0, 0.45) 52.9%,
        hsla(0, 0%, 0, 0.352) 58.8%,
        hsla(0, 0%, 0, 0.259) 64.7%,
        hsla(0, 0%, 0, 0.175) 71%,
        hsla(0, 0%, 0, 0.104) 77.5%,
        hsla(0, 0%, 0, 0.049) 84.5%,
        hsla(0, 0%, 0, 0.013) 91.9%,
        hsla(0, 0%, 0, 0) 100%
    );
}
@mixin grad-gray-900-horizontal(){
background:
    linear-gradient(
        to left,
        hsl(0, 0%, 3.9%) 0%,
        hsla(0, 0%, 3.9%, 0.987) 8.1%,
        hsla(0, 0%, 3.9%, 0.951) 15.5%,
        hsla(0, 0%, 3.9%, 0.896) 22.5%,
        hsla(0, 0%, 3.9%, 0.825) 29%,
        hsla(0, 0%, 3.9%, 0.741) 35.3%,
        hsla(0, 0%, 3.9%, 0.648) 41.2%,
        hsla(0, 0%, 3.9%, 0.55) 47.1%,
        hsla(0, 0%, 3.9%, 0.45) 52.9%,
        hsla(0, 0%, 3.9%, 0.352) 58.8%,
        hsla(0, 0%, 3.9%, 0.259) 64.7%,
        hsla(0, 0%, 3.9%, 0.175) 71%,
        hsla(0, 0%, 3.9%, 0.104) 77.5%,
        hsla(0, 0%, 3.9%, 0.049) 84.5%,
        hsla(0, 0%, 3.9%, 0.013) 91.9%,
        hsla(0, 0%, 3.9%, 0) 100%
    );
}
@mixin grad-gray-900-horizontal-inv(){
background:
    linear-gradient(
        to right,
        hsl(0, 0%, 3.9%) 0%,
        hsla(0, 0%, 3.9%, 0.987) 8.1%,
        hsla(0, 0%, 3.9%, 0.951) 15.5%,
        hsla(0, 0%, 3.9%, 0.896) 22.5%,
        hsla(0, 0%, 3.9%, 0.825) 29%,
        hsla(0, 0%, 3.9%, 0.741) 35.3%,
        hsla(0, 0%, 3.9%, 0.648) 41.2%,
        hsla(0, 0%, 3.9%, 0.55) 47.1%,
        hsla(0, 0%, 3.9%, 0.45) 52.9%,
        hsla(0, 0%, 3.9%, 0.352) 58.8%,
        hsla(0, 0%, 3.9%, 0.259) 64.7%,
        hsla(0, 0%, 3.9%, 0.175) 71%,
        hsla(0, 0%, 3.9%, 0.104) 77.5%,
        hsla(0, 0%, 3.9%, 0.049) 84.5%,
        hsla(0, 0%, 3.9%, 0.013) 91.9%,
        hsla(0, 0%, 3.9%, 0) 100%
    );
}
@mixin grad-gray-900-vertical(){
background:
    linear-gradient(
        to top,
        hsl(0, 0%, 3.9%) 0%,
        hsla(0, 0%, 3.9%, 0.987) 8.1%,
        hsla(0, 0%, 3.9%, 0.951) 15.5%,
        hsla(0, 0%, 3.9%, 0.896) 22.5%,
        hsla(0, 0%, 3.9%, 0.825) 29%,
        hsla(0, 0%, 3.9%, 0.741) 35.3%,
        hsla(0, 0%, 3.9%, 0.648) 41.2%,
        hsla(0, 0%, 3.9%, 0.55) 47.1%,
        hsla(0, 0%, 3.9%, 0.45) 52.9%,
        hsla(0, 0%, 3.9%, 0.352) 58.8%,
        hsla(0, 0%, 3.9%, 0.259) 64.7%,
        hsla(0, 0%, 3.9%, 0.175) 71%,
        hsla(0, 0%, 3.9%, 0.104) 77.5%,
        hsla(0, 0%, 3.9%, 0.049) 84.5%,
        hsla(0, 0%, 3.9%, 0.013) 91.9%,
        hsla(0, 0%, 3.9%, 0) 100%
    );
}
@mixin grad-gray-800-vertical(){
background:
    linear-gradient(
        to top,
        hsl(300, 2%, 12%) 0%,
        hsla(300, 2%, 12%, 0.987) 8.1%,
        hsla(300, 2%, 12%, 0.951) 15.5%,
        hsla(300, 2%, 12%, 0.896) 22.5%,
        hsla(300, 2%, 12%, 0.825) 29%,
        hsla(300, 2%, 12%, 0.741) 35.3%,
        hsla(300, 2%, 12%, 0.648) 41.2%,
        hsla(300, 2%, 12%, 0.55) 47.1%,
        hsla(300, 2%, 12%, 0.45) 52.9%,
        hsla(300, 2%, 12%, 0.352) 58.8%,
        hsla(300, 20%, 12%, 0.259) 64.7%,
        hsla(300, 2%, 12%, 0.175) 71%,
        hsla(300, 2%, 12%, 0.104) 77.5%,
        hsla(300, 2%, 12%, 0.049) 84.5%,
        hsla(300, 2%, 12%, 0.013) 91.9%,
        hsla(300, 2%, 12%, 0) 100%
    );
}
@mixin grad-gray-850-vertical(){
background:
    linear-gradient(
        to top,
        hsl(0, 0%, 8%) 0%,
        hsla(0, 0%, 8%, 0.987) 8.1%,
        hsla(0, 0%, 8%, 0.951) 15.5%,
        hsla(0, 0%, 8%, 0.896) 22.5%,
        hsla(0, 0%, 8%, 0.825) 29%,
        hsla(0, 0%, 8%, 0.741) 35.3%,
        hsla(0, 0%, 8%, 0.648) 41.2%,
        hsla(0, 0%, 8%, 0.55) 47.1%,
        hsla(0, 0%, 8%, 0.45) 52.9%,
        hsla(0, 0%, 8%, 0.352) 58.8%,
        hsla(300, 20%, 12%, 0.259) 64.7%,
        hsla(0, 0%, 8%, 0.175) 71%,
        hsla(0, 0%, 8%, 0.104) 77.5%,
        hsla(0, 0%, 8%, 0.049) 84.5%,
        hsla(0, 0%, 8%, 0.013) 91.9%,
        hsla(0, 0%, 8%, 0) 100%
    );
}

@mixin grad-gray-200-vertical(){
    background:
        linear-gradient(
            to top,
            hsl(180%, 2%, 92%) 0%,
            hsla(180%, 2%, 92%, 0.987) 8.1%,
            hsla(180%, 2%, 92%, 0.951) 15.5%,
            hsla(180%, 2%, 92%, 0.896) 22.5%,
            hsla(180%, 2%, 92%, 0.825) 29%,
            hsla(180%, 2%, 92%, 0.741) 35.3%,
            hsla(180%, 2%, 92%, 0.648) 41.2%,
            hsla(180%, 2%, 92%, 0.55) 47.1%,
            hsla(180%, 2%, 92%, 0.45) 52.9%,
            hsla(180%, 2%, 92%, 0.352) 58.8%,
            hsla(180%, 2%, 92%, 0.259) 64.7%,
            hsla(180%, 2%, 92%, 0.175) 71%,
            hsla(180%, 2%, 92%, 0.104) 77.5%,
            hsla(180%, 2%, 92%, 0.049) 84.5%,
            hsla(180%, 2%, 92%, 0.013) 91.9%,
            hsla(180%, 2%, 92%, 0) 100%
        );
    }