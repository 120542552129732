////////////////////////////////
// PagCorp
////////////////////////////////
.PagCorp {
    overflow: hidden; background-color: $gray-100; color: $gray-900;position: relative;
    div { z-index: 2;}
    .containerTitPag {
        position: relative; z-index: 2;
        h1 { font-weight: 800; letter-spacing: -.02em;}
        &::after { content: ""; z-index: 1; position: absolute; bottom: 0; left: -80px; width: calc(100% + 104px); height: 200px;
            @include grad-gray-200-vertical();
        }
    }
    .ContenidCorp {
        h1, h2, h3, h4 , h5, h6 {
            font-weight: 800; letter-spacing: -.02em; margin-bottom: 2rem;
        }
        h6 { text-transform: uppercase;}
        p { 
            margin-bottom: 2rem;letter-spacing: -.01em;
            strong { color:$primary;}
        }
        ul, ol { 
            margin-bottom: 2rem;
            li { margin-bottom:.5rem;}
        }
    }
}

////////////////////////////////
// PagInfo
////////////////////////////////
.PagInfo {
    overflow: hidden; background-color: $gray-900; color: $gray-100;position: relative;
    div { z-index: 4;}
    .containerInfo {
        position: relative; z-index: 2;
        h1, h2, h3, h4, h5, h6 { font-weight: 700; letter-spacing: -.02em; line-height: 1.1;}
        &:nth-child(even) {
            &::after { content: ""; z-index: 1; position: absolute; top: 0; bottom: 0; height: 100%; left: -80px; width: calc(100% + 104px);
                background-color: $dark;
            }
        }
        &:last-child {
            &::before { 
                content: ""; z-index: 2; position: absolute; bottom: 0; left: -80px; width: calc(100% + 104px); height: 50%; 
                @include grad-gray-900-vertical();
            }
        }
        .btn {
            &:hover { color:$gray-100;}
        }
    }
    #contInfo-uno, #contInfo-dos, #contInfo-tres {
        position: relative;
        &::before { content: ""; position: absolute; z-index: 3;}
    }
    #contInfo-uno {
        padding-top: 35%;
        &::before { width: 100%; height: 100%; top: 0; right: 0; background: url(../img/7tv-1.jpg) top center no-repeat; background-size: contain; }
        @include media-breakpoint-up(md) {
            padding-top: 0%;
            &::before { background-position: right 40%; background-size:contain; width: 65%; right: -10%; }
        }
        @include media-breakpoint-up(xxl) {
            &::before { background-position: right center;  right: -5%; }
        }
    }
    #contInfo-dos {
        padding-top: 35%;
        &::before { width: 100%; height: 100%; top: 0; right: 0; background: url(../img/7tv-2.jpg) top center no-repeat; background-size: contain; }
        @include media-breakpoint-up(md) {
            padding-top: 0%;
            &::before { background-position: left 40%; background-size:contain; width: 75%; left: -10%; right: auto; }
        }
        @include media-breakpoint-up(xxl) {
            &::before { left:-5%;}
        }
    }
    #contInfo-tres {
        padding-top: 40%;
        &::before { width: 90%; height: 40%; top: 5%; right: 5%; background: url(../img/7tv-3.png) top center no-repeat; background-size: contain; }
        @include media-breakpoint-up(md) {
            padding-top: 0%;
            &::before { background-position: right 50%; background-size:contain; width: 65%; right: 0%; top: 25%; }
        }
        @include media-breakpoint-up(lg) {
            &::before { width: 50%; top: 0%; height: 100%; }
        }
        @include media-breakpoint-up(xxl) {
            &::before { background-position: right center;  right: 5%; height: 70%; top:15%; }
        }
    }

}
////////////////////////////////
// FAQS
////////////////////////////////
.FAQS {
    .pregunta {
        background: $gray-750; transition: $transition-medium;
        span {color: $gray-200;}
        i { transition: $transition-short;}
        &[aria-expanded=true] , &:hover {
            background: $gray-700; cursor: pointer;
            span {color: $gray-100;}
        }
        &[aria-expanded=true] {
            i { transform: rotate(45deg); color: $primary-tint;}
        }
    }
    .respuesta {
        background: $gray-800;
        p {
            color: $gray-400;
            &:last-child { margin-bottom: 0;}
        }
    }
}

////////////////////////////////
// TARIFAS
////////////////////////////////
#TARIFAS {
    .PriceCard {
        transition: $transition-medium; position: relative; z-index: 1;
        &:hover , &.PriceCard_Selected {
            transform:scale(1.05); box-shadow: var(--shadow-m); z-index: 2; border-color: $primary!important;
        }
    }
    .PriceCard_Selected {
        button { background-color: $primary!important; border-color: $primary!important; }
    }
}

////////////////////////////////
// PagConf
////////////////////////////////
.PagConf {
    background: url(../img/bg-login.jpg) center center no-repeat; background-size: cover; position: relative; overflow: hidden;background-attachment: fixed;
    &::before { content: ""; position: absolute; top:-1px; left: -1px; width: 101%; height: 50%; z-index: 1; @include grad-gray-900-vertical(); transform: rotate(180deg); opacity: .95;}
    &::after { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 50%; z-index: 1; @include grad-gray-900-vertical();}
    .grads {
        position: absolute; top:0;left: 0; width: 100%; height: 100%; z-index: 1;
        &::before { content: ""; position: absolute; top:-1px; left:-1px; width: 25%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(180deg);}
        &::after { content: ""; position: absolute; top:-1px; right:-1px; width: 25%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(0deg); }
    }
    .container-login {
        position: relative;z-index: 2; height: calc(100vh - var( --nav-space-mob));
        @include media-breakpoint-up(sm) { height: calc(100vh - var( --nav-space-tablet)); }
        @include media-breakpoint-up(lg) { height: calc(100vh - var( --nav-space-pc)); }
    }
    margin-top:calc(var(--nav-space-mob) * -1);
    @include media-breakpoint-up(sm) { margin-top:calc(var(--nav-space-tablet) * -1); }
    @include media-breakpoint-up(lg) { margin-top:calc(var(--nav-space-pc) * -1); }
}



////////////////////////////////
// PagReg
////////////////////////////////
.PagReg {
    @include media-breakpoint-up(sm) {
        background: url(../img/bg-reg.jpg) center center no-repeat; background-size: cover; position: relative; overflow: hidden;
        &::before { content: ""; position: absolute; top:-1px; left: -1px; width: 101%; height: 50%; z-index: 1; @include grad-gray-900-vertical(); transform: rotate(180deg); opacity: .95;}
        &::after { content: ""; position: absolute; bottom:-1px; left: -1px; width: 101%; height: 50%; z-index: 1; @include grad-gray-900-vertical();}
        .grads {
            position: absolute; top:0;left: 0; width: 100%; height: 100%; z-index: 1; opacity: .75;
            &::before { content: ""; position: absolute; top:-1px; left:-1px; width: 25%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(180deg);}
            &::after { content: ""; position: absolute; top:-1px; right:-1px; width: 25%; height: 101%; z-index: 1; @include grad-gray-900-horizontal(); transform: rotate(0deg); }
        }
    }
    .container-reg {
        position: relative;z-index: 2; 
        // height: calc(100vh - var( --nav-space-mob));
        // @include media-breakpoint-up(sm) { height: calc(100vh - var( --nav-space-tablet)); }
        // @include media-breakpoint-up(lg) { height: calc(100vh - var( --nav-space-pc)); }
    }
    h1, h2, h3, h4, h5, h6 { font-weight: 700; letter-spacing: -.02em; line-height: 1.1;} 
    #btn_Google, #btn_Facebook { font-size: 14px;}
    #btn_Google { 
        background-color: #EB4437; border-color: #EB4437;
        &:hover { background-color: #d13b30; border-color: #d13b30; }
    }
    #btn_Facebook { 
        background-color: #3B5998; border-color: #3B5998;
        &:hover {background-color: #324a80; border-color: #324a80;}
    }
    &.noBg {
        background: none!important;
    }
}

@mixin rotate($deg) {
	-webkit-transform: rotate($deg);
	-moz-transform: rotate($deg);
	-ms-transform: rotate($deg);
	-o-transform: rotate($deg);
	transform: rotate($deg);
}


.nav-progress {
	display: flex; justify-content: space-between; overflow: hidden; margin: 0; width: 100%; height: 44px; /*border: 4px solid $gray-900;*/ background-color: $gray-800;
	> div {
		position: relative; flex-grow: 1; color: $gray-200; text-align: center; padding: 15px 0px 15px 0px; font-size: 14px; font-weight: bold; line-height: 14px; text-transform: uppercase;
		&.complete {
			background-color: $primary-shade; color: $gray-100;
			.arrow {
				border: 6px solid $gray-900!important; background: $primary-shade!important;
			}
		}
		&.active {
			background-color: $primary; color: #FFF;
			.arrow {
				background: $primary!important;
			}
		}
	}
    a {
        text-decoration:none; color: $gray-100;
        &:hover { color: $gray-500!important;}
    }
    @include media-breakpoint-down(lg) {
        flex-wrap: wrap; height: auto;
        > div { width: 50%; border-bottom: 6px solid $gray-900;}
    }
}

.arrow-wrapper {
	position: absolute; top: 0px; right: 0px;
	.arrow-cover {
		position: absolute; overflow: hidden; width: 40px; height: 50px;
		.arrow {
			position: absolute; left: -40px; top:-8px; z-index: 2; width: 60px; height:60px; border: 6px solid $gray-900; background: transparent; @include rotate(45deg);
		}
	}
}