
////////////////////////////////
// TOPNAV
////////////////////////////////
.TOPNAV { 
    background:rgba(10,10,10,0.66); /*box-shadow: 0px 0px 20px #000;*/ -webkit-backdrop-filter: saturate(180%) blur(5px); backdrop-filter: saturate(180%) blur(5px);
    height: var( --nav-space-mob) ;z-index: 1042; padding-right:var( --nav-space-mob);
    @include media-breakpoint-up(sm) { height: var( --nav-space-tablet); padding: .5rem var( --nav-space-tablet) .5rem 0; }
    @include media-breakpoint-up(md) { height: var( --nav-space-tablet); padding: 12px 0 12px 0; }
    @include media-breakpoint-up(lg) { height: var( --nav-space-pc); padding: 16px 0; }

    a { color: $gray-100; text-decoration: none!important;
        &:hover { color: $primary;}
        &.offcanvasTrigger {
            text-decoration: none;
            width: var( --nav-space-mob); height: var( --nav-space-mob) ; background-color: $gray-100;
            @include media-breakpoint-up(sm)  { width:var( --nav-space-tablet); height:var( --nav-space-tablet);}
            @include media-breakpoint-up(lg) { width: var( --nav-space-pc); height: var( --nav-space-pc);}

            i::before { top: 50%; position: absolute; left: 50%; transform: translate(-50%,-50%);color: $gray-100!important;}
            i.bx-menu-alt-left { display: block;}
            i.bx-x { display: none;}
            &.offCanvasOpen { background-color: $primary;
                span i , h6 { color: $gray-100;}
                i.bx-menu-alt-left { display: none;}
                i.bx-x { display: block;}
            }
        }
        &.SearchTrigger {
            text-decoration: none;
        }
    }
    .RedesSociales {
        a { opacity: .6;
            &:hover { color: $gray-100; opacity: 1;}
        }
    }
    .BuscarPc {
        * {transition: $transition-short;}
        input { transition: $transition-medium; max-width: 600px;outline: none!important;}
        input.closed { max-width: 0; padding: 0;}
        &:hover { cursor: pointer; 
            button { opacity: 1;}
        }
    }

    .Brand {
        min-width: var( --nav-space-mob); height: var( --nav-space-mob) ; background-color: $gray-100;
        @include media-breakpoint-up(sm)  { width:var( --nav-space-tablet); height:var( --nav-space-tablet);}
        @include media-breakpoint-up(lg) { width: var( --nav-space-pc); height: var( --nav-space-pc);}
        background-color: $gray-100; background-image: url(../img/logo7TV.svg); background-position:center center; background-repeat:no-repeat; background-size: contain;
    }
    .SearchBox {
        max-width: 600px;
        button:hover { background: $primary;}
    }
    .Login { }
    .LoggedIn {
        .dropdown-toggle { 
            transition: none; padding: 16px 1rem; margin-top: -16px; height: calc(100% + 32px); outline: none!important;
            .circle { background-color: $primary; color: $gray-100; font-weight: 800; font-size: 14px; text-align: center; line-height: 40px; width:48px; height: 48px; border-radius: 50%; border:4px solid $primary-shade ;}
            .name { line-height: 48px; color: $gray-500; font-weight: 700; font-size: 14px; text-transform: capitalize; }
            &::after { position:absolute; top:50%; right: 1rem; transform: translateY(-50%);color: $gray-500;}
            &.show {
                background-color: $gray-850;
                .name { color: $gray-100; }
                &::after {color: $primary;}
            }
        }
        .dropdown-menu {
            border-color:transparent; margin-top: .5rem; font-size: 14px; left: auto; right: 0; min-width: 100%;background-color: $gray-850; 
            &.show {}
            li .dropdown-item { padding:0.5rem 1rem; text-transform: capitalize;
                &:hover { background-color: rgba($gray-900, .5);}
                i {font-size: 22px!important;}
            }
        }
        @include media-breakpoint-down(md)  { 
            margin: 0!important;
            .dropdown-toggle {
                height: calc(100% + 24px); margin-top: -12px;
            }
            .dropdown-menu { margin-top: 0.25rem;}
        }
        @include media-breakpoint-down(sm)  { 
            .dropdown-toggle {
                height: calc(100% + 24px); margin-top: -16px;
                .circle {
                    line-height: 36px; width: 44px; height: 44px;
                }
            }
            .dropdown-menu { 
                margin-top: -1px; left: auto; right: 0; max-width: 100vw; position: fixed; top: var( --nav-space-mob);
            }
        }
        @include media-breakpoint-down(lg)  { 
            .dropdown-toggle::after { display: none;}
        }
    }

}

////////////////////////////////
// SIDEBAR
////////////////////////////////
.SIDEBAR {
    background-color:$gray-900;     z-index: 1041; 
    height: 100vh; position: fixed; left: -80px; top:0; width: var( --nav-space-mob) ; padding-top: var( --nav-space-mob) ; transition: $transition-medium;
    @include media-breakpoint-up(md) { left: 0; width: var( --nav-space-tablet);  padding-top: var( --nav-space-tablet); }
    @include media-breakpoint-up(lg) { left: 0; width: var( --nav-space-pc);  padding-top: var( --nav-space-pc); }
    nav {
        a { text-decoration: none; 
            * {transition: $transition-short;}
            h6 { font-size: 11px; margin: 0;}
            span i , h6 { color: $gray-600;}
            &:hover { background-color: $gray-850;
                span i , h6 { color: $gray-200;}
            }
            &.active {
                background-color: $gray-800;
                span i { color: $primary;}
                h6 { color: $gray-100;}
            }
            &.offcanvasTrigger {
                i.bx-menu-alt-left { display: block;}
                i.bx-x { display: none;}
                &.offCanvasOpen { background-color: $primary;
                    span i , h6 { color: $gray-100;}
                    i.bx-menu-alt-left { display: none;}
                i.bx-x { display: block;}
                }
            }
        }
    }
}

////////////////////////////////
// SIDEMENU
////////////////////////////////
.SIDEMENU {
    top:var( --nav-space-mob) ; left:0px; transition: $transition-medium; background-color: $gray-900; width: 100%;
    @include media-breakpoint-up(sm) { width: 300px; top:var( --nav-space-tablet); }
    @include media-breakpoint-up(md) { top:var( --nav-space-tablet); left:var( --nav-space-tablet);}
    @include media-breakpoint-up(lg) { top:var( --nav-space-pc); left:var( --nav-space-pc);}
    // @include media-breakpoint-up(md) { top:80px; left: 80px;}
    * {transition: $transition-short;}
    .sidenav {
        background-color: rgba($gray-800, .5); height: calc(100% - 58px);overflow-y: scroll; position: relative;background-color: $gray-850;
        -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;
        @include media-breakpoint-up(md) { height: 100%; }
        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
        ul.nav { list-style: none;
            li {
                a { color: $gray-100; font-size:15px; font-weight: 400; padding: 1rem 1.5rem; border-bottom: 2px solid $gray-900; text-transform: uppercase;
                    span { 
                        // font-weight: 700; color: $gray-500;
                        width: 26px; height: 1rem; background: url(../img/logo7TV-white.svg) left center no-repeat; background-size: contain; display: inline-block; align-self: center;
                    }
                    &:hover { background-color: rgba($gray-800, .5);}
                    small { font-size:15px; font-weight: bold;}
                }
                .submenu { list-style: none; margin: 0; padding: 0; background: $gray-900;
                    li {
                        a { font-size: 15px; color: $gray-400; padding:0.75rem 1.5rem; font-weight: 400; text-transform: none;
                            &:hover { color:$gray-200;}
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        float: left; width: 100%;
                        li { 
                            float: left; width: 50%;
                            a { padding:1rem 1.5rem;}
                        }
                    }
                }
                &.has-submenu {
                    > a { position: relative;
                        &::after { content: "\ea50"; font-family: boxicons!important; position: absolute; right: 1rem;top: 0.45rem; font-size: 30px; color: $gray-400;}
                        &:hover { 
                            &::after { color: $gray-300;}
                        }
                    }
                    .nav-link-active { background-color: rgba($gray-800, .75); color: $gray-400!important;
                        &::after { transform: rotate(90deg) translateX(-4px); color: $primary!important;}
                    }
                    ul li a:hover { color: $gray-400;}
                }
            }
        }
    }
    .offcanvas-footer {
        display: flex; justify-content: start; padding: 1rem 1.5rem; position: absolute; bottom: 0; left: 0; width: 100%; height: 62px; border-top:3px solid $gray-700;
        a { color: $gray-100; text-decoration: none; padding-right: 2rem;}
    }
}

////////////////////////////////
// navFooter ( corp : footer )
////////////////////////////////
.navFooter {
    ul.nav {
        li { font-size: 13px; margin-right: 1rem; margin-bottom: 0.5rem;
            a { text-decoration: none; color: $gray-100; font-weight: 700; }
            a:hover { color: $primary; }
        }
    }
}

////////////////////////////////
// navSide ( corp : side )
////////////////////////////////
.navSide {
    ul.nav {
        padding: .5rem 1rem 0 .5rem; display: flex; flex-direction: column;
        li { font-size: 1.1rem; margin-right: 1rem; margin-bottom: 1rem;
            a { text-decoration: none; color: $gray-700; font-weight: 800; letter-spacing: -.02em; position: relative; display: flex;
                &::before { content: "\ea55"; font-family: boxicons!important; font-weight: 400; width: 1.75rem;transition: $transition-short;}
                &:hover { 
                    color: $primary; 
                    &::before { margin-left: .5rem;}
                }
            }
        }
    }
}