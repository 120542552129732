@import 'colors';
@import 'variables';
@import "node_modules/bootstrap/scss/bootstrap";
@import 'typography';
@import url('https://cdn.jsdelivr.net/npm/boxicons@latest/css/boxicons.min.css');
@import 'navigation';
@import 'hero';
@import 'modals';
@import 'sliders_listados';
@import 'corporate';
@import 'plugins';
@import 'parrilla';

body {
    padding-top:var( --nav-space-mob); background-color: $gray-900; color: $gray-100;
    @include media-breakpoint-up(sm) { padding-top: var( --nav-space-tablet); }
    @include media-breakpoint-up(lg) { padding-top: var( --nav-space-pc); }
}
body.modal-open {
    overflow: hidden!important;
}

.preloader { position: fixed; left: 0px; top: 0px; width: 100%; height: 100%; z-index: 9999; background: #000; }
.preloader svg { max-width:300px; height: auto; position: absolute;top: 50%; left: 50%; transform: translate(-50% , -50%); }

.MAIN_CONTENT {
    margin-left: 0px; padding-left:.25rem; padding-right:.25rem; overflow: hidden;
    @include media-breakpoint-up(sm) { padding-left:1rem; padding-right:1rem; }
    @include media-breakpoint-up(md) { margin-left: var( --nav-space-tablet); }
    @include media-breakpoint-up(lg) { margin-left: var( --nav-space-pc); }
}
.FOOTER {
  margin-left: 0px; padding-left:.25rem; padding-right:.25rem;
  @include media-breakpoint-up(sm) { padding-left:1rem; padding-right:1rem; }
  @include media-breakpoint-up(md) { margin-left: var( --nav-space-tablet); }
  @include media-breakpoint-up(lg) { margin-left: var( --nav-space-pc); }
  a * { transition: $transition-short;}
  .RedesSociales {
      a { opacity: .6; color: $gray-100;
          &:hover {  opacity: 1;}
      }
  }
}
.ic-paypal { width: 280px; opacity: 0.7;}


// GLOBAL
a {
    transition: $transition-short;
    &.btn:hover { color:inherit!important;}
}
p a:hover { color:$primary-tint!important;}
.btn-check:focus + .btn, .btn:focus { box-shadow: none!important;}

// Botones Favoritos
.btnFav {
    &:hover i { color: $primary!important;}
    .bxs-heart { display: none;}
    .bx-heart { display: block;}
}
.FAV {
    .btnFav {
        i::before { /*content: "\ede2";*/ color: $primary;}
        span {display: none;}
        .bx-heart { display: none;}
        .bxs-heart { display: block;}
    }
}

.resp-container { 
    position: relative; overflow: hidden; padding-top: 56.25%;
    iframe {
        position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0;
    }
}
#ModalReproductor {
    .resp-container { 
        position: absolute; overflow: hidden; padding-top: 56.25%; top: 50%; width: calc(100% - 2rem); transform: translateY(-50%);
    } 
}

////////////////////////////////
// hideScrollbar
////////////////////////////////
.hideScrollbar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
}

////////////////////////////////
// FORMS
////////////////////////////////
.SelectDark {
    background-color: $gray-800; color: $gray-500; border-color: $gray-800; background-image: url(../img/ic/chevron-down.svg); background-size: 16px 16px; -webkit-appearance:none;
}


////////////////////////////////
// ADDTOANY
////////////////////////////////
.a2a_kit { 
    position: fixed; top:180px; right:1.25rem; z-index: 4; width: 32px; display: flex; flex-direction: column; justify-content: end; 
    h6 {color: $gray-500; font-weight: bold; letter-spacing: 1px; text-transform:uppercase; font-size: 11px; transform: rotate(90deg) translateX(-58px) translateY(-2px);}
    a {
        margin-bottom: 0.5rem; 
        span { background-color: transparent!important; opacity: .4; }
        &:hover span { opacity: 1;}
    }
    @include media-breakpoint-up(sm) {
        top: 220px; right: 1.5rem;
    }
    @include media-breakpoint-up(md) {
    }
}

////////////////////////////////
// TABS
////////////////////////////////
.Tabs7TV {
    .nav-tabs  {
        li {
            button { 
                color: $gray-600;
                &.active { color: $gray-100!important; border-bottom: 4px solid $primary;}
                &:hover { color: $primary;}
            }
        }
    }
}

////////////////////////////////
// PERFIL Página
////////////////////////////////
.PerfilUsuario {
    overflow: hidden;
    .containerTitPerfil {
        position: relative;
        &::after { content: ""; z-index: 1; position: absolute; bottom: 0; left: -80px; width: calc(100% + 104px); height: 200px;
            @include grad-gray-850-vertical();
        }
        .circle { background-color: $primary; color: $gray-100; font-weight: 800; font-size: 24px; text-align: center; line-height: 80px; width: 96px; height: 9containerInfo6px; border-radius: 50%; border: 8px solid $primary-shade; display: block;}
        .name { line-height: 48px; color: $gray-500; font-weight: 700; font-size: 14px; text-transform: capitalize; }
    }
    position: relative;
    div { z-index: 2;}
    .Filas {
        @include media-breakpoint-up(xl) {
            padding-right: 3rem;
        }
        .Fila {
            button { min-width: 200px;}
        }
    }
}

////////////////////////////////
// PRICING
////////////////////////////////
.PriceCard {
    // max-width: 460px;
    h6.rounded-pill { position: relative; z-index: 2; }
    h6.rounded-pill:nth-child(2) { z-index: 1; }
}



////////////////////////////////
// 404
////////////////////////////////

:root {
    --shadow: #000;
    --header: #D6D7D7;
    --color: #fafafa;
    --lit-header: #D6D7D7;
    --speed: 4s;
}
@property --swing-x {
  initial-value: 0;
  inherits: false;
  syntax: '<integer>';
}
@property --swing-y {
  initial-value: 0;
  inherits: false;
  syntax: '<integer>';
}
.ErrorPageFoot FOOTER { margin-top: 0!important;}
.ErrorPage { position: relative;background-color: #1F1E1F;
    div { z-index: 2; position: relative;}
    // .container-fluid {position: relative;
    //     &::after { content: ""; z-index: 1; position: absolute; top: 0; bottom: 0; height: 100%; left: -80px; width: calc(100% + 104px);
            
    //     }
    // }
  * {
    box-sizing: border-box;
    transform-style: preserve-3d;
  }
  
  h1 { position: relative; z-index: 2; letter-spacing: -0.75rem;
    -webkit-animation: swing var(--speed) infinite alternate ease-in-out;
            animation: swing var(--speed) infinite alternate ease-in-out;
    font-size: clamp(5rem, 40vmin, 20rem);
    transform: translate3d(0, 0, 0vmin);
    --x: calc(50% + (var(--swing-x) * 0.5) * 1%);
    background: radial-gradient(var(--lit-header), var(--header) 45%) var(--x) 100%/200% 200%;
    -webkit-background-clip: text;
    color: transparent;
  }
  h1:after {
    -webkit-animation: swing var(--speed) infinite alternate ease-in-out;
            animation: swing var(--speed) infinite alternate ease-in-out;
    content: "404";
    position: absolute;
    top: 0;
    left: 0;
    color: var(--shadow);
    filter: blur(.5vmin);
    transform: scale(1.05) translate3d(0, 6%, -10vmin) translate(calc((var(--swing-x, 0) * 0.05) * 1%), calc((var(--swing-y) * 0.05) * 1%));
  }
  .cloak {
    animation: swing var(--speed) infinite alternate-reverse ease-in-out;
    height: 100%;
    width: 100%;
    transform-origin: 50% 30%;
    transform: rotate(calc(var(--swing-x) * -0.25deg));
    // background: radial-gradient(40% 40% at 50% 42%, transparent, #1F1E1F 35%);
  }
  .cloak__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
  }
  .cloak__container {
    height: 250vmax;
    width: 250vmax;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @-webkit-keyframes swing {
    0% {
      --swing-x: -100;
      --swing-y: -100;
    }
    50% {
      --swing-y: 0;
    }
    100% {
      --swing-y: -100;
      --swing-x: 100;
    }
  }
  @keyframes swing {
    0% {
      --swing-x: -100;
      --swing-y: -100;
    }
    50% {
      --swing-y: 0;
    }
    100% {
      --swing-y: -100;
      --swing-x: 100;
    }
  }
}