
// CUSTOM FONTS - MANROPE
// font-family: 'Bebas Neue'; - 400
// font-family: 'Manrope', sans-serif; - 300 / 400 / 700 / 800
// font-family: 'DM Serif Text', serif; - 400
// font-family: 'Poppins', sans-serif; - 700

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=DM+Serif+Text&family=Manrope:wght@200;300;400;700;800&family=Poppins:wght@500&display=swap');

// $main-font : 'Manrope', sans-serif;
$headings-font-weight: 200;
$font-weight-bolder:800;
$font-family-sans-serif:'Manrope', sans-serif;
$font-family-secondary:'Poppins', sans-serif;

$font-family-informativo:'Manrope', sans-serif;
$font-family-documental:'Verdana', sans-serif;
$font-family-deportes:'Bebas Neue', sans-serif;
$font-family-magazine:'Poppins', sans-serif;
$font-family-cultural:'DM Serif Text', serif;
$font-family-cofrade: 'times new roman',times,serif, serif;
$font-family-toros:'times new roman', serif;

.titPagina {
    color: $gray-100; display: flex; letter-spacing: -.02em; font-weight: 300; display: flex; flex-wrap: wrap; line-height: 1; margin-right: 2rem;
    span { color:$primary; font-weight: 700;}
}
.subtitPagina {
    color: $gray-400;
    strong { color:$primary; margin: 0 .25rem; }
    span { color: $gray-500; font-weight: 700;}
}
.tit{
    color: $gray-100; display: flex; letter-spacing: -.02em; font-weight: 700;
    i { color: $primary; font-size: 1.25em;}
    .link-categoria-carrusel { color: inherit; text-decoration: none;}
}
.titPerfil { }


.titPrograma { line-height: 1;}

.TIPO_informativo .titPrograma {
    font-family: $font-family-informativo;
    span { font-size:.9em; }
    @include media-breakpoint-up(sm) {
        span { font-size:.9em; }
    }
}
.TIPO_documental .titPrograma {
    font-family: $font-family-documental; letter-spacing: -1px;
    @include media-breakpoint-up(sm) {
        span { font-size:.8em; }
    }
}
.TIPO_deporte .titPrograma {
    font-family: $font-family-deportes; font-weight: 400;
    @include media-breakpoint-up(sm) {
        span { font-size:1.1em; }
    }
    @include media-breakpoint-down(sm) {
        span { font-size:1.25em; }
    }
}
.TIPO_magazine .titPrograma {
    font-family: $font-family-magazine;
    @include media-breakpoint-up(sm) {
        span { font-size:.9em; }
    }
    @include media-breakpoint-down(sm) {
        span { font-size:.9em; }
    }
}
.TIPO_cultural .titPrograma {
    font-family: $font-family-cultural;
    @include media-breakpoint-down(sm) {
        span { font-size:1.25em; }
    }
}
.TIPO_cofrade .titPrograma {
    font-family: $font-family-cofrade; font-weight: 400;
    @include media-breakpoint-down(sm) {
        span { font-size:1.2em; }
    }
}
.TIPO_toros .titPrograma {
    font-family: $font-family-toros; text-transform: uppercase;
    @include media-breakpoint-down(sm) {
        span { font-size:1.2em; }
    }
}